{
  "attributes": {
    "Empresa": "Company",
    "Licencia": "License",
    "Documento": "Document",
    "Email": "Email",
    "Teléfono": "Phone",
    "País": "Country",
    "Ciudad": "City",
    "Provincia": "Province",
    "Código Postal": "Postal Code",
    "Dirección": "Address",
    "Persona de Contacto": "Contact Person",
    "Nombre": "Name",
    "Apellidos": "Last Name",
    "Password": "Password"
  },
  "actions": {
    "Enviar": "Send",
    "Cancelar": "Cancel",
    "Guardar": "Save",
    "Reservar": "Book",
    "Solicitar modificación": "Request modification",
    "Contactar con soporte": "Contact support",
    "Acceder": "Access",
    "Solicitar acceso": "Request access",
    "Olvidaste tu contraseña?": "Forgot your password?",
    "Mantenme logueado en este dispositivo": "Keep me logged in on this device",
    "Iniciar sesión": "Log in",
    "previous": "Previous",
    "next": "Next",
    "save-budget": "Save budget",
    "reserve": "Reserve",
    "edit": "Edit",
    "seleccionar": "Select"
  },
  "404": {
    "title": "This page’s gone fishing.",
    "text": "If you feel that it’s not right, please send us a message at ",
    "back_button": "Back to dashboard"
  },
  "language": {
    "portuguese": "Português",
    "english": "English",
    "spanish": "Spanish",
    "italian": "Italian",
    "deutch": "Deutch",
    "french": "French"
  },
  "menu": {
    "home": "Home",
    "auth": "Auth",
    "buttons": "Buttons",
    "timelines": "Timelines",
    "budgets": "Budgets",
    "reservations": "Reservations",
    "preferences": "Account preferences",
    "payments": "Payments",
    "settings": "Application settings",
    "404": "404",
    "faq": "FAQ",
    "customers": "Customers",
    "paths": "Paths",
    "trails": "Trails",
    "prices": "Prices",
    "invoices": "Invoices"
  },
  "messages": {
    "all": "See all messages",
    "new": "New messages from {name}",
    "mark_as_read": "Mark As Read",
    "mark_as_unread": "Mark As Unread",
    "answer": "Answer",
    "answered": "Answered",
    "send-answer": "Send answer",
    "Ya tienes acceso a la gestión de tu agencia?": "Do you already have access to manage your agency?",
    "No tienes acceso a la gestión de tu agencia?": "Do you not have access to manage your agency?",
    "request_sent": "Request sent, you will receive a confirmation email shortly",
    "error_sending_request": "Error"
  },
  "notifications": {
    "all": "See all notifications",
    "less": "See less notifications",
    "mark_as_read": "Mark as read",
    "sentMessage": "sent you a message",
    "uploadedZip": "uploaded a new Zip file with {type}",
    "startedTopic": "started a new topic"
  },
  "user": {
    "agency": "Agency",
    "language": "Change language",
    "logout": "Logout",
    "profile": "Profile",
    "settings": "Settings",
    "faq": "FAQ",
    "help": "?",
    "helpAndSupport": "Help & support",
    "account": "Account",
    "explore": "Explore",
    "data": "User data",
    "password": {
      "old": "Old password",
      "new": "New password",
      "confirm": "Confirm password",
      "rules": {
        "length": "The password must be at least 8 characters long",
        "minSpecialChars": "The password must contain at least one special character"
      }
    }
  },
  "support": {
    "send-message": "Send message to support",
    "message-sent": "Support message sent"
  },
  "message": "Message",
  "helpAndSupport": "Help & support",
  "aboutVuesticAdmin": "About Vuestic Admin",
  "search": {
    "placeholder": "Search..."
  },
  "reservations": {
    "reservation": "Reservation",
    "customer": "Customer",
    "rooms": "Rooms",
    "services": "Services",
    "payments": "Payments",
    "people-to": "persons",
    "total": "Total",
    "payment-method": "Payment method",
    "payd": "Paid",
    "modification-request": "Modification request",
    "request-modification": "Request modification",
    "date-start": "Start date",
    "from-date": "from",
    "to-date": "to",
    "send-modification": "Send modification",
    "modification-sent": "Modification sent",
    "set-customer": "Set customer",
    "card-payment": "Card payment",
    "transfer-payment": "Transfer payment",
    "no-specified": "Not specified",
    "pay": "Pay",
    "price-per-person": "€/person",
    "trail": "Trail",
    "select-trail": "Select trail"
  },
  "forms": {
    "Datos de Agencia": "Agency Data",
    "Datos de Contacto": "Contact Data",
    "reset-password": "Reset password",
    "invalid": "Check the marked fields to continue."
  },
  "trails": {
    "noches": "nights",
    "trail": "Trail",
    "stages": "Stages",
    "single_room": "Single room",
    "double_room": "Double room",
    "triple_room": "Triple room",
    "breakfasts": "Breakfasts",
    "dinners": "Dinners",
    "bags_transport": "Bags transport",
    "bicycles": "Bicycles",
    "prices": {
      "people-to": "person",
      "download-csv": "Download CSV"
    }
  },
  "prices": {
    "desde": "from",
    "people-to": "person"
  },
  "titles": {
    "options": "Options"
  },
  "customers": {
    "customers": "Customers",
    "new": "New customer",
    "edit": "Edit customer",
    "updated": "Customer updated",
    "error": "Error updating customer"
  },
  "password": {
    "new": {
      "rules": {
        "length": "The password must be at least 8 characters long",
        "minSpecialChars": "The password must contain at least one special character",
        "differentFromOld": "The new password must be different from the old password"
      },
      "confirm": {
        "required": "Password confirmation is required",
        "match": "Passwords match"
      },
      "old": {
        "required": "Old password is required"
      },
      "new": "New password",
      "required": "Password is required"
    },
    "forgot": {
      "question": "Forgot your password?",
      "email": "Email",
      "action": "Send",
      "description": "Enter your email address and we will send you a new password"
    },
    "updated": "Password updated"
  },
  "agency": {
    "data": {
      "updated": "Agency data updated"
    }
  },
  "info": {
    "loading": "Loading..."
  },
  "customer": {
    "email": "Customer email",
    "name_and_last_name": "Name and last name",
    "phone": "Phone",
    "select-country": "Select country",
    "no-email": "No email found",
    "search": "Search customers"
  },
  "home": {
    "youtube-message": "Latest videos on our Youtube channel",
    "youtube-suscribe": "Subscribe to our Youtube channel to receive notifications about new videos",
    "download-guide": "DOWNLOAD GUIDE"
  }
}
{
  "attributes": {
    "Empresa": "Empresa",
    "Licencia": "Licença",
    "Documento": "Documento",
    "Email": "Email",
    "Teléfono": "Telefone",
    "País": "País",
    "Ciudad": "Cidade",
    "Provincia": "Província",
    "Código Postal": "Código Postal",
    "Dirección": "Endereço",
    "Persona de Contacto": "Pessoa de Contato",
    "Nombre": "Nome",
    "Apellidos": "Sobrenome",
    "Password": "Senha"
  },
  "actions": {
    "Enviar": "Enviar",
    "Cancelar": "Cancelar",
    "Guardar": "Salvar",
    "Reservar": "Reservar",
    "Solicitar modificación": "Solicitar modificação",
    "Contactar con soporte": "Contatar suporte",
    "Acceder": "Acessar",
    "Solicitar acceso": "Solicitar acesso",
    "Olvidaste tu contraseña?": "Esqueceu sua senha?",
    "Mantenme logueado en este dispositivo": "Mantenha-me logado neste dispositivo",
    "Iniciar sesión": "Entrar",
    "previous": "Anterior",
    "next": "Próximo",
    "save-budget": "Salvar orçamento",
    "reserve": "Reservar",
    "edit": "Editar",
    "seleccionar": "Selecione"
  },
  "404": {
    "title": "Esta página foi pescar.",
    "text": "Se você acha que não está certo, por favor nos envie uma mensagem para ",
    "back_button": "Voltar ao painel"
  },
  "language": {
    "portuguese": "Português",
    "english": "Inglês",
    "spanish": "Espanhol",
    "deutch": "Alemão",
    "italian": "Italiano",
    "french": "Francês"
  },
  "menu": {
    "home": "Início",
    "auth": "Autenticação",
    "buttons": "Botões",
    "timelines": "Cronogramas",
    "budgets": "Orçamentos",
    "reservations": "Reservas",
    "preferences": "Preferências",
    "payments": "Pagamentos",
    "settings": "Configurações",
    "login-singup": "Entrar/Cadastrar",
    "404": "404",
    "faq": "FAQ",
    "customers": "Clientes",
    "paths": "Caminhos",
    "trails": "Trilhas",
    "prices": "Preços",
    "invoices": "Faturas"
  },
  "trails": {
    "noches": "noites",
    "trail": "Trilha",
    "stages": "Etapas",
    "single_room": "Quarto único",
    "double_room": "Quarto duplo",
    "triple_room": "Quarto triplo",
    "breakfasts": "Almoços",
    "dinners": "Jantares",
    "bags_transport": "Bagagens de transporte",
    "bicycles": "Bicicletas",
    "prices": {
      "people-to": "pessoa",
      "download-csv": "Baixar CSV"
    }
  },
  "messages": {
    "all": "Ver todas as mensagens",
    "new": "Novas mensagens de {name}",
    "mark_as_read": "Marcar como lido",
    "mark_as_unread": "Marcar como não lido",
    "answer": "Responder",
    "answered": "Respondido",
    "send-answer": "Enviar resposta",
    "Ya tienes acceso a la gestión de tu agencia?": "Você já tem acesso para gerenciar sua agência?",
    "No tienes acceso a la gestión de tu agencia?": "Você não tem acesso para gerenciar sua agência?",
    "request_sent": "Solicitação enviada, você receberá em breve uma confirmação por e-mail",
    "error_sending_request": "Error"
  },
  "notifications": {
    "all": "Ver todas as notificações",
    "mark_as_read": "Marcar como lido",
    "sentMessage": "{name} enviou uma mensagem",
    "uploadedZip": "{name} enviou um novo arquivo Zip com {type}",
    "startedTopic": "{name} iniciou um novo tópico"
  },
  "user": {
    "agency": "Agência",
    "language": "Idioma",
    "logout": "Sair",
    "profile": "Perfil",
    "settings": "Configurações",
    "faq": "FAQ",
    "help": "?",
    "helpAndSupport": "Ajuda e Suporte",
    "account": "Conta",
    "explore": "Explorar",
    "data": "Dados de usuário",
    "password": {
      "old": "Senha antiga",
      "new": "Nova senha",
      "confirm": "Confirmar senha",
      "rules": {
        "length": "A senha deve ter pelo menos 8 caracteres",
        "minSpecialChars": "A senha deve ter pelo menos um caractere especial"
      }
    }
  },
  "support": {
    "send-message": "Enviar mensagem para suporte",
    "message-sent": "Mensagem de suporte enviada"
  },
  "message": "Mensagem",
  "helpAndSupport": "Ajuda e Suporte",
  "search": {
    "placeholder": "Procurar..."
  },
  "reservations": {
    "reservation": "Reserva",
    "customer": "Cliente",
    "rooms": "Quartos",
    "services": "Serviços",
    "payments": "Pagamentos",
    "people-to": "pessoas",
    "total": "Total",
    "payment-method": "Método de pagamento",
    "payd": "Pago",
    "modification-request": "Solicitação de modificação",
    "request-modification": "Solicitar modificação",
    "date-start": "Data de início",
    "from-date": "de",
    "to-date": "até",
    "send-modification": "Enviar modificação",
    "modification-sent": "Modificação enviada",
    "set-customer": "Definir cliente",
    "card-payment": "Pagamento com cartão",
    "transfer-payment": "Pagamento por transferência",
    "no-specified": "Não especificado",
    "pay": "Pagar",
    "price-per-person": "€/pessoa",
    "trail": "Trilha",
    "select-trail": "Selecione a trilha"
  },
  "forms": {
    "Datos de Agencia": "Dados da Agência",
    "Datos de Contacto": "Dados de Contato",
    "reset-password": "Redefinir senha",
    "invalid": "Verifique os campos marcados para continuar."
  },
  "titles": {
    "options": "Opções"
  },
  "customers": {
    "customers": "Clientes",
    "new": "Novo cliente",
    "edit": "Editar cliente",
    "updated": "Cliente atualizado",
    "error": "Erro ao atualizar cliente"
  },
  "password": {
    "new": {
      "rules": {
        "length": "A senha deve ter pelo menos 8 caracteres",
        "minSpecialChars": "A senha deve ter pelo menos um caractere especial",
        "differentFromOld": "A nova senha deve ser diferente da senha antiga"
      },
      "confirm": {
        "required": "Confirmação de senha é obrigatória",
        "match": "As senhas coincidem"
      },
      "old": {
        "required": "Senha antiga é obrigatória"
      },
      "new": "Nova senha",
      "required": "Campo obrigatório"
    },
    "forgot": {
      "question": "Esqueceu sua senha?",
      "email": "Email",
      "action": "Enviar",
      "description": "Digite seu email e enviaremos unha nova senha"
    },
    "updated": "Senha atualizada"
  },
  "agency": {
    "data": {
      "updated": "Dados da agência atualizados"
    }
  },
  "info": {
    "loading": "Carregando..."
  },
  "customer": {
    "email": "Email do cliente",
    "name_and_last_name": "Nome e sobrenome",
    "phone": "Telefone",
    "select-country": "Selecione o país",
    "no-email": "Nenhum email encontrado",
    "search": "Pesquisar clientes"
  },
  "home": {
    "youtube-message": "Últimos vídeos no nosso canal do Youtube",
    "youtube-suscribe": "Inscreva-se no nosso canal do Youtube para receber notificações sobre novos vídeos",
    "download-guide": "DESCARREGAR GUIA"
  },
  "prices": {
    "people-to": "pessoa",
    "desde": "de"
  }
}
{
  "attributes": {
    "Empresa": "Entreprise",
    "Licencia": "Licence",
    "Documento": "Document",
    "Email": "Email",
    "Teléfono": "Téléphone",
    "País": "Pays",
    "Ciudad": "Ville",
    "Provincia": "Province",
    "Código Postal": "Code Postal",
    "Dirección": "Adresse",
    "Persona de Contacto": "Personne de Contact",
    "Nombre": "Nom",
    "Apellidos": "Nom de Famille",
    "Password": "Mot de Passe"
  },
  "actions": {
    "Enviar": "Envoyer",
    "Cancelar": "Annuler",
    "Guardar": "Sauvegarder",
    "Reservar": "Réserver",
    "Solicitar modificación": "Demander une modification",
    "Contactar con soporte": "Contacter le support",
    "Acceder": "Accéder",
    "Solicitar acceso": "Demander l'accès",
    "Olvidaste tu contraseña?": "Mot de passe oublié?",
    "Mantenme logueado en este dispositivo": "Gardez-moi connecté sur cet appareil",
    "Iniciar sesión": "Se connecter",
    "previous": "Précédent",
    "next": "Suivant",
    "save-budget": "Enregistrer le devis",
    "reserve": "Réserver",
    "edit": "Modifier",
    "seleccionar": "Sélectionner"
  },
  "404": {
    "title": "Cette page est partie à la pêche.",
    "text": "Si vous pensez que ce n'est pas correct, veuillez nous envoyer un message à ",
    "back_button": "Retour au tableau de bord"
  },
  "language": {
    "portuguese": "Portugais",
    "english": "Anglais",
    "spanish": "Espagnol",
    "deutch": "Allemand",
    "italian": "Italien",
    "french": "Français"
  },
  "menu": {
    "home": "Accueil",
    "auth": "Authentification",
    "buttons": "Boutons",
    "timelines": "Chronologies",
    "budgets": "Devis",
    "reservations": "Réservations",
    "preferences": "Préférences",
    "payments": "Paiements",
    "settings": "Paramètres",
    "login-singup": "Connexion/Inscription",
    "404": "404",
    "faq": "FAQ",
    "customers": "Clients",
    "paths": "Chemins",
    "trails": "Sentiers",
    "prices": "Prix",
    "invoices": "Factures"
  },
  "messages": {
    "all": "Voir tous les messages",
    "new": "Nouveaux messages de {name}",
    "mark_as_read": "Marquer comme lu",
    "mark_as_unread": "Marquer comme non lu",
    "answer": "Répondre",
    "answered": "Répondu",
    "send-answer": "Envoyer une réponse",
    "Ya tienes acceso a la gestión de tu agencia?": "Avez-vous déjà accès à la gestion de votre agence?",
    "No tienes acceso a la gestión de tu agencia?": "N'avez-vous pas accès à la gestion de votre agence?",
    "request_sent": "Demande envoyée, vous recevrez prochainement un e-mail de confirmation",
    "error-sending-request": "Error"
  },
  "notifications": {
    "all": "Voir toutes les notifications",
    "mark_as_read": "Marquer comme lu",
    "sentMessage": "{name} vous a envoyé un message",
    "uploadedZip": "{name} a téléchargé un nouveau fichier Zip avec {type}",
    "startedTopic": "{name} a lancé un nouveau sujet"
  },
  "user": {
    "agency": "Agence",
    "language": "Langue",
    "logout": "Déconnexion",
    "profile": "Profil",
    "settings": "Paramètres",
    "faq": "FAQ",
    "help": "?",
    "helpAndSupport": "Aide et Support",
    "account": "Compte",
    "explore": "Explorer",
    "data": "Données utilisateur",
    "password": {
      "old": "Ancien mot de passe",
      "new": "Nouveau mot de passe",
      "confirm": "Confirmer le mot de passe",
      "rules": {
        "length": "Le mot de passe doit comporter au moins 8 caractères",
        "minSpecialChars": "Le mot de passe doit comporter au moins un caractère spécial"
      }
    }
  },
  "support": {
    "send-message": "Envoyer un message au support",
    "message-sent": "Message de support envoyé"
  },
  "message": "Message",
  "helpAndSupport": "Aide et Support",
  "search": {
    "placeholder": "Rechercher..."
  },
  "reservations": {
    "reservation": "Réservation",
    "customer": "Client",
    "rooms": "Chambres",
    "services": "Services",
    "payments": "Paiements",
    "people-to": "personnes",
    "total": "Total",
    "payment-method": "Méthode de paiement",
    "payd": "Payé",
    "modification-request": "Demande de modification",
    "request-modification": "Demander une modification",
    "date-start": "Date de début",
    "from-date": "du",
    "to-date": "au",
    "send-modification": "Envoyer la modification",
    "modification-sent": "Modification envoyée",
    "set-customer": "Définir le client",
    "card-payment": "Paiement par carte",
    "transfer-payment": "Paiement par virement",
    "no-specified": "Non spécifié",
    "pay": "Payer",
    "price-per-person": "€/personne",
    "trail": "Sentier",
    "select-trail": "Sélectionnez la piste"
  },
  "forms": {
    "Datos de Agencia": "Agency Data",
    "Datos de Contacto": "Contact Data",
    "reset-password": "Réinitialiser le mot de passe",
    "invalid": "Vérifiez les champs marqués pour continuer."
  },
  "trails": {
    "noches": "nuits",
    "trail": "Sentier",
    "stages": "Etapes",
    "single_room": "Chambre unique",
    "double_room": "Chambre double",
    "triple_room": "Chambre triple",
    "breakfasts": "Déjeuners",
    "dinners": "Dîners",
    "bags_transport": "Bagages de transport",
    "bicycles": "Vélos",
    "prices": {
      "people-to": "personne",
      "download-csv": "Télécharger CSV"
    }
  },
  "prices": {
    "desde": "depuis",
    "people-to": "person"
  },
  "titles": {
    "options": "Options"
  },
  "customers": {
    "customers": "Clients",
    "new": "Nouveau client",
    "edit": "Modifier le client",
    "updated": "Client mis à jour",
    "error": "Erreur lors de la mise à jour du client"
  },
  "password": {
    "new": {
      "rules": {
        "length": "Le mot de passe doit comporter au moins 8 caractères",
        "minSpecialChars": "Le mot de passe doit comporter au moins un caractère spécial",
        "differentFromOld": "Le nouveau mot de passe doit être différent de l'ancien mot de passe"
      },
      "confirm": {
        "required": "La confirmation du mot de passe est requise",
        "match": "Les mots de passe correspondent"
      },
      "old": {
        "required": "L'ancien mot de passe est requis"
      },
      "new": "Nouveau mot de passe",
      "required": "Le mot de passe est requis"
    },
    "forgot": {
      "question": "Mot de passe oublié?",
      "email": "Email",
      "action": "Envoyer",
      "description": "Entrez votre adresse e-mail et nous vous enverrons un nouveau mot de passe"
    },
    "updated": "Mot de passe mis à jour"
  },
  "agency": {
    "data": {
      "updated": "Données de l'agence mises à jour"
    }
  },
  "info": {
    "loading": "Chargement..."
  },
  "customer": {
    "email": "Email du client",
    "name_and_last_name": "Nom et nom de famille",
    "phone": "Téléphone",
    "select-country": "Sélectionnez le pays",
    "no-email": "Aucun email trouvé",
    "search": "Rechercher des clients"
  },
  "home": {
    "youtube-message": "Dernières vidéos sur notre chaîne Youtube",
    "youtube-suscribe": "Abonnez-vous à notre chaîne Youtube pour recevoir des notifications sur les nouvelles vidéos",
    "download-guide": "TÉLÉCHARGER LA GUIDE"
  }
}